import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SESSION_USER_WITH_ACCOUNTS } from '/client/app/graphql/queries/user';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import UserView from '/client/app/views/user/user';

interface IProps {
	urlParameters: Record<string, string>;
}

export default function UserContainer(props: IProps) {
	const { urlParameters } = props;
	const { data, loading } = useQuery(SESSION_USER_WITH_ACCOUNTS);
	return (
		<LoadingWrapper width="100%" height="3em" loading={loading}>
			<UserView
				urlParameters={urlParameters}
				sessionUser={data?.sessionUser}
			/>
		</LoadingWrapper>
	);
}
