import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getTimeDelta from '/client/app/util/dateUtils';

import './userSidebar.scss';
import EndlessContentDiscoveryWidget from '/client/app/components/endlessContentDiscoveryWidget/endlessContentDiscoveryWidget';
import Hideable from '/client/app/components/common/hideable';
import UserLink from '/client/app/components/userLink/userLink';

interface IProps {
	subscribers: number;
	moderatorReputation: number;
	memberSince: Date;
	username: string;
	sessionUser?: any;
	subscribe: () => Promise<any>;
	unsubscribe: () => Promise<any>;
	displayContentDiscovery: boolean;
	isAdmin: boolean;
}

export default function UserSidebar(props: IProps) {
	const {
		subscribers,
		memberSince,
		moderatorReputation,
		username,
		subscribe,
		unsubscribe,
		sessionUser,
		displayContentDiscovery,
		isAdmin,
	} = props;
	const [modifyingSubscription, setModifyingSubscription] = useState(false);

	// TODO: Refactor this out, place higher in tree
	const isSubscribed = !!sessionUser?.subscriptions?.users?.find((u: any) => u.usernameLowercase === username.toLowerCase());

	async function toggleSubscription() {
		setModifyingSubscription(true);
		await (isSubscribed ? unsubscribe() : subscribe());
		setModifyingSubscription(false);
	}

	let truncatedUsername = username;
	if (truncatedUsername.length > 26) truncatedUsername = `${truncatedUsername.substring(0, 23)}...`;

	return (
		<div className="userSidebar">
			<div className="userSidebarInner">
				<div className="userSidebarTitle">
					<UserLink username={username} isAdmin={isAdmin} />
				</div>
				<button
					type="button"
					disabled={modifyingSubscription || !sessionUser}
					className="tinyButton"
					onClick={toggleSubscription}
				>
					{isSubscribed ? 'unsubscribe' : 'subscribe'}
				</button>
				<br />
				{subscribers} subscribers
				<br />
				<br />
				member {username === 'pfyt' ? 'since the beginning' : `for ${getTimeDelta(new Date(memberSince), '')}`}
				<br />
				moderator reputation is {username === 'pfyt' ? 'infinite' : moderatorReputation}
			</div>
			<Hideable hidden={!displayContentDiscovery}>
				<EndlessContentDiscoveryWidget />
			</Hideable>
		</div>
	);
}

UserSidebar.propTypes = {
	username: PropTypes.string.isRequired,
	subscribers: PropTypes.number.isRequired,
	moderatorReputation: PropTypes.number.isRequired,
	memberSince: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
	subscribe: PropTypes.func.isRequired,
	unsubscribe: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	sessionUser: PropTypes.object,
	displayContentDiscovery: PropTypes.bool,
};

UserSidebar.defaultProps = {
	sessionUser: undefined,
	displayContentDiscovery: true,
};
