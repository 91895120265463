import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SESSION_USER_WITH_ACCOUNTS, USER_BY_USERNAME } from '/client/app/graphql/queries/user';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import UserSidebar from '/client/app/components/userSidebar/userSidebar';
import { SUBSCRIBE_TO_USER, UNSUBSCRIBE_FROM_USER } from '/client/app/graphql/userActions';

interface IProps {
	username: string;
	displayContentDiscovery: boolean;
}

export default function UserSidebarContainer(props: IProps) {
	const { username, displayContentDiscovery } = props;
	const { data, loading } = useQuery(USER_BY_USERNAME, { variables: { username } });

	const { data: sessionUserData, loading: sessionUserLoading } = useQuery(SESSION_USER_WITH_ACCOUNTS);

	const [subscribe] = useMutation(SUBSCRIBE_TO_USER);
	const [unsubscribe] = useMutation(UNSUBSCRIBE_FROM_USER);

	async function doSubscribe() {
		await subscribe({
			variables: {
				username,
			},
		});
	}

	async function doUnsubscribe() {
		await unsubscribe({
			variables: {
				username,
			},
		});
	}

	return (
		<LoadingWrapper width="100%" height="10em" loading={loading || sessionUserLoading}>
			{() => {
				const {
					username: usernameInCorrectCase,
					subscribers,
					memberSince,
					moderatorReputation,
					memberOf,
				} = data.userByUsername;
				return (
					<UserSidebar
						username={usernameInCorrectCase}
						sessionUser={sessionUserData?.sessionUser}
						subscribe={doSubscribe}
						unsubscribe={doUnsubscribe}
						moderatorReputation={moderatorReputation}
						subscribers={subscribers}
						memberSince={memberSince}
						displayContentDiscovery={displayContentDiscovery}
						isAdmin={memberOf.find((group: any) => group.name === 'Site Admins' && group.builtin)}
					/>
				);
			}}
		</LoadingWrapper>
	);
}

UserSidebarContainer.propTypes = {
	username: PropTypes.string.isRequired,
	displayContentDiscovery: PropTypes.bool,
};

UserSidebarContainer.defaultProps = {
	displayContentDiscovery: true,
};
