import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import '/client/app/styles/text.scss';
import '/client/app/styles/controls.scss';
import CollapsingSidebarLayout from '/client/app/components/layouts/collapsingSidebarLayout/collapsingSidebarLayout';
import UserSidebarContainer from '/client/app/components/userSidebar/userSidebarContainer';
import './user.scss';

const FeedContainer = loadable(() => import('/client/app/components/feed/feedContainer'));

export default function UserView({ urlParameters }) {
	const { user } = urlParameters;

	return (
		<>
			<div className="mainTitle">{`user ${user}`}</div>
			<CollapsingSidebarLayout>
				<FeedContainer username={user} />
				<UserSidebarContainer username={user} />
			</CollapsingSidebarLayout>
		</>
	);
}

UserView.propTypes = {
	urlParameters: PropTypes.objectOf(PropTypes.string),
	sessionUser: PropTypes.shape({
		_id: PropTypes.string,
		username: PropTypes.string,
	}),
};

UserView.defaultProps = {
	urlParameters: {},
	sessionUser: undefined,
};
